/* Header */
.n-header {
    height: 50px;
    position: relative;
    z-index: 1301;
}

.page-mypages .n-header {
    margin-bottom: 10px;
}

.n-header-item:last-child {
    padding-right: 0;
}

.page-editor .n-header {
    height: 0;
}

@media (min-width: 1025px) {
    .n-header {
        height: 80px;
        transition: height .1s ease-in-out;
    }
    .has-shrinked-header .n-header {
        transition-duration: .3s;
        height: 60px;
    }

    .page-mypages .n-header {
        margin-bottom: 30px;
    }
}

.page-digital-catalog .n-header {
    margin-bottom: -1px;
}

/* Sticky bar*/
.n-stickybar {
    position: fixed;
    z-index: 1500;
    top: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    height: inherit;
    min-width: 320px;
    background: rgba(255, 255, 255, 1);
    border-bottom: 2px solid var(--grey-100);
    transition: background .1s ease-in-out;
}

.has-shrinked-header .n-stickybar {
    background: rgba(255, 255, 255, .9);
    transition-duration: .3s;
}

.has-shrinked-header .n-header:hover .n-stickybar {
    background: rgba(255, 255, 255, 1);
}

.n-stickybar-content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    padding: 0 10px;
    height: inherit;
    box-sizing: border-box;
}

@media (min-width: 1025px) {
    .n-stickybar-content {
        padding: 0 30px;
    }
}

@media (min-width: 1200px) {
    .n-stickybar-content {
        padding: 0 60px;
    }
}


/* Header items */
.n-header-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    order: 4;
}

.n-header-item {
    margin-left: 0;
}

.n-header-item-link {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    width: 30px;
    font-size: var(--font-size-body1);
    color: var(--grey-700);
}

.n-header-item-link:hover {
    color: var(--text-hover);
}

.n-header-item-link:focus {
    color: var(--text-primary);
}

.n-header-login--logged-in .n-header-item-link {
    color: var(--secondary-main);
}

.personal-menu__profile-picture--sponsor {
    background-color: var(--info-main);
}

@media (min-width: 375px) {
    .n-header-item-link {
        width: 40px;
    }
}

@media (min-width: 480px) {
    .n-header-item {
        margin-left: 8px;
    }
}

@media (min-width: 1025px) {
    .n-header-item {
        margin-left: 0;
    }

    .n-header-item {
        padding-right: 30px;
    }

    .n-header-item-link:focus:hover {
        color: var(--text-hover);
    }

    .n-header-item-link {
        width: 40px; /* was:auto */
    }
}

@media (min-width: 1200px) {
    .n-header-item {
        padding-right: 45px;
    }
}

@media (min-width: 1280px) {
    .n-header-item-link {
        font-size: var(--font-size-body1);
    }
}

.n-header-item-text {
    display: none;
}

@media (min-width: 1025px) {
    .n-header-item-text {
        line-height: 10px;
    }
}

.n-header-item-icon {
    display: block;
    font-style: normal;
    font-size: var(--font-size-icon6);
}

.n-header-item-image-icon {
    display: flex;
    width: 25px;
    height: 25px;
    margin: 0 auto; /* this is needed to make it centered within the 30/40px wide box allocated for a menu item */
    justify-content: center;
    align-items: center;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
    background-color: var(--secondary-light);
    color: var(--common-white);
    font-size: var(--font-size-overline);
    font-weight: bold;
}

.n-header-sponsor {
    display: flex;
    align-items: center;
    height: 43px;
    box-sizing: border-box;
}

.n-header-sponsor .n-header-item-image-icon {
    background-color: var(--info-main);
    border: 2px solid var(--common-white);
}

/* Header items order  */
@media (max-width: 1024px) {
    .n-mainmenu {
        order: 1;
    }

    .n-header-search {
        order: 2;
    }
}

/* N-panel */
.n-panel {
    display: flex;
    overflow-y: hidden;
    box-sizing: border-box;
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    left: -100vw;
    width: 100vw;
    min-width: 280px;
    height: calc(100% - 50px);
    -webkit-font-smoothing: antialiased; /* to stop flickering of text in safari */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all .3s ease-in;
    transition-property: left, width;
}

@media (min-width: 1025px) {
    .n-panel {
        top: 80px;
        height: calc(100% - 80px);
    }

    .has-shrinked-header .n-panel {
        top: 60px;
        height: calc(100% - 60px);
    }
}

.n-overlay {
    display: none;
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    backdrop-filter: blur(2px);
    background-color: hsla(0, 0%, 30%, 0.3);
}

@media (min-width: 1025px) {
    .n-overlay {
        top: 80px;
    }

    .has-shrinked-header .n-overlay {
        top: 60px;
    }
}


/* Fixes for legacy blocks*/
.n-header ~ .frontpage .ui-promo-article {
    margin: 0;
}

.n-header ~ .w-breadcrumbs {
    margin-top: 15px;
}

@media (min-width: 1025px) {
    .n-header ~ .w-breadcrumbs {
        max-width: 1900px;
    }
}
